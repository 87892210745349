<template>
  <UsersForm
    @save="createUser"
    :user="user"
    :type="'create'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    UsersForm: () => import('./UsersForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    askToLeave: true,
    user: {},
  }),
  methods: {
    createUser() {
      const formData = new FormData()
      const userKeys = Object.keys(this.user)
      const userValues = Object.values(this.user)

      for (let i = 0; i < userKeys.length; i++) {
        if (userValues[i] !== null) {
          formData.append(userKeys[i], userValues[i])
        }
      }
      this.askToLeave = false
      this.$api
        .post('people', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Usuário cadastrado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'users' })
            },
          })
        })
        .catch((error) => {
          let errorMessage =
            'Ops! Falha ao tentar cadastrar este usuário. Verifique se os campos estão preenchidos corretamente.'

          if (error.response && error.response.data) {
            const data = error.response.data
            if (data.errors) {
              // Concatena todas as mensagens de erro dos campos
              const errorMessages = Object.values(data.errors)
                .map((msgs) => msgs.join('<br>'))
                .join('<br>')
              errorMessage = errorMessages
            } else if (data.message) {
              errorMessage = data.message
            }
          }

          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: errorMessage,
            caption: '',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
